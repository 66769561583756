<template>
  <ion-page>
    <ion-content>
      <ion-refresher slot="fixed" pull-min="100" pull-factor="0.5" @ionRefresh="doRefresh($event)">
        <ion-refresher-content refreshing-spinner="crescent" />
      </ion-refresher>

      <div class="content">
        <ion-list v-if="hasPosts" class="posts">
          <ion-item v-for="post in posts" :key="post.id" lines="none">
            <Promotion :promotion="post" v-if="post.type === 'promotion'" />
            <Post :post-data="post" v-else />
          </ion-item>
        </ion-list>

        <div v-if="isLoading" class="loader">
          <ion-spinner name="crescent"></ion-spinner>
        </div>

        <ion-infinite-scroll v-if="hasPosts" @ionInfinite="loadPosts" :disabled="!hasNextPage">
          <ion-infinite-scroll-content />
        </ion-infinite-scroll>

        <div v-if="!hasNextPage" class="no-more-posts">
          <ion-img v-if="isAuthenticated && activePet" :src="noMorePostsImg" alt="Nincs több bejegyzés" />
          <ion-text> Hoppá, itt a vége! </ion-text>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { App } from '@capacitor/app'
import { defineComponent, computed, onMounted } from 'vue'
import {
  IonPage,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonList,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonText,
  IonImg,
  IonSpinner,
} from '@ionic/vue'

import useAuth from '@/composables/auth'
import usePosts from '@/composables/posts'

import Post from '@/components/Posts/Post'
import Promotion from '@/components/Posts/Promotion'
import usePets from '@/composables/pets'

import { refreshOutline } from 'ionicons/icons'

export default defineComponent({
  name: 'Home',
  components: {
    IonPage,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    IonList,
    IonItem,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonText,
    IonImg,
    IonSpinner,
    Post,
    Promotion,
  },
  setup() {
    const { isAuthenticated } = useAuth()
    const { fetchPosts, posts, loadPosts, hasNextPage, isLoading } = usePosts()
    const { activePet } = usePets()

    const noMorePostsImg = computed(() => {
      return activePet.value.species.id === 1
        ? require(`@/assets/images/icons/dog.svg`)
        : require(`@/assets/images/icons/cat.svg`)
    })
    const hasPosts = computed(() => posts.value && posts.value.length)

    const doRefresh = async event => {
      try {
        await fetchPosts()
      } finally {
        event.target.complete()
      }
    }

    const ionViewDidEnter = () => {
      App.addListener('backButton', () => {
        App.exitApp()
      })
    }

    const ionViewWillLeave = () => {
      App.removeAllListeners()
    }

    onMounted(fetchPosts)

    return {
      ionViewDidEnter,
      ionViewWillLeave,
      isAuthenticated,
      posts,
      loadPosts,
      hasNextPage,
      noMorePostsImg,
      activePet,
      isLoading,
      doRefresh,
      refreshOutline,
      hasPosts,
    }
  },
})
</script>

<style lang="scss" scoped>
ion-refresher {
  z-index: 11;

  @media (max-width: 767px) {
    top: 56px;

    &.ios {
      top: 76px;

      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        // iPhone 12 Mini, iPhone 11 Pro, iPhone Xs, and iPhone X
        top: 105px;
      }

      @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
        // iPhone 12 and iPhone 12 Pro
        top: 105px;
      }

      @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
        // iPhone 11 and iPhone XR
        top: 105px;
      }

      @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
        // iPhone 11 Pro Max and iPhone Xs Max
        top: 105px;
      }
    }
  }

  @media (min-width: 768px) {
    top: 80px;
  }
}

ion-list {
  @media (max-width: 767px) {
    --padding-top: 20px;
    //margin-top: -36px;
  }

  @media (min-width: 768px) {
    padding-top: 0;
    //margin-top: -30px;
  }
}

ion-item {
  --inner-padding-end: 0;
  --padding-start: 0;
}

ion-item:not(:first-child) {
  --inner-padding-end: 0;
  --padding-start: 0;

  @media (max-width: 767px) {
    --padding-top: 20px;
  }

  @media (min-width: 768px) {
    --padding-top: 40px;
  }
}

ion-toolbar {
  --min-height: 60px;
}

ion-menu-button {
  --color: var(--ion-text-color);
}

ion-icon {
  --fill: var(--ion-text-color);
}

.no-more-posts {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  font-family: var(--ion-poppins-font);

  ion-img {
    height: 45px;
    width: 53px;
    margin-bottom: 12px;
  }
}

.loader {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
</style>
