<template>
  <ion-page>
    <ion-content>
      <div class="content" :class="{ loading: !postData }">
        <Post v-if="postData" :post-data="postData" @scroll-to-hash="scrollToHash" />
        <div v-else>
          <lottie-animation
            ref="anim"
            :autoPlay="true"
            :playInterval="10 * 1e3"
            :animationData="require('@/assets/images/post/loading.json')"
          />
        </div>
        <Unavailable v-if="hasError" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { IonPage, IonContent } from '@ionic/vue'

import usePosts from '@/composables/posts'
import Post from '@/components/Posts/Post'
import Unavailable from '@/components/Global/Unavailable'

export default defineComponent({
  name: 'PostPage',
  components: {
    IonPage,
    IonContent,
    Post,
    Unavailable,
  },
  setup() {
    const route = useRoute()
    const { fetchPost, hasError } = usePosts()

    const postData = ref(null)

    const ionViewWillEnter = async () => {
      postData.value = await fetchPost(route.params.id)
    }

    const scrollToHash = () => {
      if (!route.query || !route.query.comment) {
        return
      }

      const element = document.querySelector(`#comment-${route.query.comment}`)
      if (element) {
        setTimeout(
          () =>
            element.scrollIntoView({
              behavior: 'smooth',
            }),
          1000,
        )
      }
    }

    return {
      ionViewWillEnter,
      postData,
      scrollToHash,
      hasError,
    }
  },
})
</script>

<style scoped>
.content {
  padding-top: 20px;
}

.content.loading {
  margin: 0 !important;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lottie-animation {
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 300px;
}
</style>
