<template>
  <ion-page>
    <ion-content>
      <div class="container" v-if="currentPage === 'upload'">
        <div class="content ion-text-center">
          <form @submit.prevent="submit">
            <div class="post-upload" :class="{ hasImage: image }">
              <ion-avatar
                v-if="!image"
                @click.prevent="getPhoto"
                :class="{ 'is-invalid': validations.image.$error || hasServerValidationError('file') }"
              >
                <lottie-animation
                  v-if="!isUploadLoading"
                  ref="anim"
                  :autoPlay="true"
                  :playInterval="10 * 1e3"
                  :animationData="require('@/assets/images/lottie/postupload.json')"
                />
                <span v-else>Feltöltés...</span>
              </ion-avatar>
              <ion-input type="hidden" name="image" v-model="image" />

              <div class="post-upload-preview">
                <ion-img v-if="image" :src="image" />

                <div class="relations" v-if="image">
                  <div class="relation" v-if="catPets.length > 0">
                    <div class="tooltip">
                      <div v-for="pet in selectedPets" :key="pet.id" class="list">
                        <ion-img :src="pet.avatar" alt="Pet avatar" class="avatar" />
                        <span>{{ pet.name }}</span>
                        <div class="remove" @click="handlePetRemove(pet)">
                          <ion-img :src="require('@/assets/images/icons/poi/bin.svg')" />
                        </div>
                      </div>
                    </div>

                    <ion-img
                      :src="require('@/assets/images/icons/post/cat.svg')"
                      style="max-width: 18px; border-radius: 0px"
                    />
                  </div>

                  <div class="relation" v-if="dogPets.length > 0">
                    <div class="tooltip">
                      <div v-for="pet in selectedPets" :key="pet.id" class="list">
                        <ion-img :src="pet.avatar" alt="Pet avatar" class="avatar" />
                        <span>{{ pet.name }}</span>
                        <div class="remove" @click="handlePetRemove(pet)">
                          <ion-img :src="require('@/assets/images/icons/poi/bin.svg')" />
                        </div>
                      </div>
                    </div>

                    <ion-img
                      :src="require('@/assets/images/icons/post/dog.svg')"
                      style="max-width: 20px; border-radius: 0px"
                    />
                  </div>

                  <div class="relation" v-if="selectedPoi">
                    <div class="tooltip">
                      <ion-img
                        :src="getCustomMarkerIcon(selectedPoi)"
                        alt="Poi ikon"
                        class="marker"
                        @click="goToWalkingMap"
                      />
                      <span @click="goToWalkingMap">{{ selectedPoi.name }}</span>
                      <div class="remove" @click="selectedPoi = null">
                        <ion-img :src="require('@/assets/images/icons/poi/bin.svg')" />
                      </div>
                    </div>

                    <ion-img :src="require('@/assets/images/icons/post/poi.svg')" />
                  </div>
                </div>
              </div>

              <ErrorMessage :field="validations.image" />
              <ServerValidation field="file" :server-validation="serverValidation" />
            </div>

            <div class="post-selectors">
              <div class="selector-box" @click="currentPage = image ? 'poi-selector' : 'upload'">
                <div class="tooltip" :style="{ opacity: showPlaceTooltip ? 1 : 0 }">
                  <ion-img :src="require('@/assets/images/icons/petmenu_infoicon.svg')" alt="Segítség ikon" />
                  <span class="danger">Új!</span> Már helyszínt is megjelölhetsz!
                </div>

                <div class="tooltip" v-if="!image && !showPlaceTooltip">
                  <ion-img :src="require('@/assets/images/icons/petmenu_infoicon.svg')" alt="Segítség ikon" />
                  Először tölts fel egy képet!
                </div>

                <ion-img :src="require('@/assets/images/icons/post/poiselect.png')" class="main-selector" />
              </div>
              <div class="selector-box" @click="currentPage = image ? 'pet-selector' : 'upload'">
                <div class="tooltip" v-if="!image">
                  <ion-img :src="require('@/assets/images/icons/petmenu_infoicon.svg')" alt="Segítség ikon" />
                  Először tölts fel egy képet!
                </div>

                <ion-img :src="require('@/assets/images/icons/post/petselect.png')" class="main-selector" />
              </div>
            </div>

            <div class="message-box">
              <ion-img :src="activePet.avatar" />

              <div class="form-group">
                <ion-textarea
                  id="content"
                  v-model="content"
                  :rows="3"
                  name="content"
                  enterkeyhint="go"
                  :class="{ 'is-invalid': hasServerValidationError('content') }"
                  class="form-control"
                  autocapitalize="sentences"
                  placeholder="Képaláírás.."
                />
                <ServerValidation field="content" :server-validation="serverValidation" />
              </div>
            </div>

            <ion-button
              type="submit"
              id="submit"
              expand="full"
              shape="round"
              :disabled="isLoading"
              strong="true"
              class="btn submit"
            >
              {{ isUpdate ? 'Kép módosítása' : 'Kép feltöltése' }}
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
          </form>
        </div>
      </div>

      <PoiSelector
        v-if="currentPage === 'poi-selector'"
        @on-submit="
          poi => {
            currentPage = 'upload'
            selectedPoi = poi
          }
        "
      />

      <PetSelector
        v-if="currentPage === 'pet-selector'"
        @on-submit="
          pets => {
            currentPage = 'upload'
            selectedPets = pets
          }
        "
      />
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { IonPage, IonContent, IonAvatar, IonImg, IonInput, IonTextarea, IonButton, IonRippleEffect } from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useRoute } from 'vue-router'

import useFormControl from '@/composables/formControl'
import usePets from '@/composables/pets'
import usePosts from '@/composables/posts'
import usePhotoGallery from '@/composables/camera'
import useToast from '@/composables/toast'
import useNavigation from '@/composables/navigation'
import { POST_EDIT_ROUTE, PET_WALKING_ROUTE } from '@/constants/routes'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

import PoiSelector from '@/components/Posts/Upload/PoiSelector'
import PetSelector from '@/components/Posts/Upload/PetSelector'

export default defineComponent({
  name: 'CreatePost',
  components: {
    IonPage,
    IonContent,
    IonAvatar,
    IonImg,
    IonInput,
    IonTextarea,
    IonButton,
    IonRippleEffect,
    ErrorMessage,
    ServerValidation,
    PoiSelector,
    PetSelector,
  },
  setup() {
    const route = useRoute()
    const { fetchPost, createPost, updatePost } = usePosts()
    const { serverValidation, hasServerValidationError } = useFormControl()
    const { takePhoto, isLoading: isUploadLoading } = usePhotoGallery()
    const { openToast } = useToast()
    const { activePet } = usePets()
    const { navigateTo } = useNavigation()

    const currentPage = ref('upload')

    const content = ref(null)
    const image = ref(null)
    const isLoading = ref(false)
    const selectedPoi = ref(null)
    const selectedPets = ref([])
    const showPlaceTooltip = ref(true)
    const hasUploadedImage = ref(false)

    const isUpdate = computed(() => route.params.id && route.name === POST_EDIT_ROUTE)

    const dogPets = computed(() => selectedPets.value.filter(p => p.species.id === 1))
    const catPets = computed(() => selectedPets.value.filter(p => p.species.id === 2))

    const rules = {
      image: { required },
    }

    const validations = useVuelidate(rules, {
      image,
    })

    onMounted(() => {
      setTimeout(() => {
        showPlaceTooltip.value = false
      }, 3500)
    })

    const getPhoto = async () => {
      try {
        serverValidation.value = {}

        const photo = await takePhoto()
        image.value = photo.url
        hasUploadedImage.value = true
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      }
    }

    const removePhoto = () => {
      image.value = null
      hasUploadedImage.value = false
      getPhoto()
    }

    const getCustomMarkerIcon = marker => {
      if (marker.type === 'walkplace') {
        return require('@/assets/images/icons/walk/walkplace-marker.svg')
      } else if (marker.type === 'dogbeach') {
        return require('@/assets/images/icons/walk/dogbeach-marker.svg')
      } else if (marker.type === 'dogfriendly') {
        return require('@/assets/images/icons/walk/dogfriendly-marker.svg')
      } else if (marker.logo) {
        return marker.logo
      } else if (marker.type !== undefined) {
        return require(`@/assets/images/icons/walk/poi/${marker.type}.svg`)
      }

      return require('@/assets/images/icons/walk/unknown-marker.svg')
    }

    const submit = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) {
        return
      }

      if (isLoading.value || !image.value) {
        return
      }

      try {
        serverValidation.value = {}
        isLoading.value = true

        const post = {
          image: hasUploadedImage.value ? image.value : undefined,
          poi_id: selectedPoi.value?.id ?? null,
          pets: selectedPets.value.map(pet => pet.id),
        }

        if (content.value) {
          Object.assign(post, { content: content.value })
        }

        if (!isUpdate.value) {
          await createPost(post)
          content.value = ''
          image.value = null
        } else {
          await updatePost({ id: route.params.id, ...post })
        }

        await openToast({
          message: 'Sikeres képfeltöltés',
          color: 'success',
        })

        setTimeout(() => {
          validations.value.image.$reset()
          validations.value.$reset()
        }, 10)
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        await openToast({
          color: 'danger',
          message: 'Sajnos nem tudtuk feltölteni a képet. Próbáld meg később!',
        })

        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    onMounted(async () => {
      if (!isUpdate.value) return

      await getPostDetails()
    })

    const ionViewWillEnter = async () => {
      if (!isUpdate.value) return

      await getPostDetails()
    }

    const getPostDetails = async () => {
      const post = await fetchPost(route.params.id)

      content.value = post.content
      image.value = post.image

      if (post.poi && !post.rating) {
        selectedPoi.value = {
          id: post.poi.id,
          name: post.poi.name,
          type: post.poi.type,
        }
      }

      if (post.pets) {
        selectedPets.value = post.pets
      }

      // @TODO: add pet selection
    }

    const goToWalkingMap = async () => {
      await navigateTo({ name: PET_WALKING_ROUTE })
    }

    const handlePetRemove = pet => {
      selectedPets.value = selectedPets.value.filter(p => p.id !== pet.id)
    }

    return {
      ionViewWillEnter,
      content,
      image,
      submit,
      isLoading,
      validations,
      serverValidation,
      hasServerValidationError,
      getPhoto,
      removePhoto,
      isUploadLoading,
      isUpdate,
      activePet,
      currentPage,
      selectedPoi,
      selectedPets,
      getCustomMarkerIcon,
      showPlaceTooltip,
      goToWalkingMap,
      dogPets,
      catPets,
      handlePetRemove,
    }
  },
})
</script>

<style lang="scss" scoped>
.post-upload {
  margin-top: 200px;
  margin-bottom: 70px;

  &.hasImage {
    margin-top: 100px;
    margin-bottom: 15px;
  }
}

.post-upload-preview {
  position: relative;

  ion-img {
    border-radius: 24px;
    overflow: hidden;
  }

  .relations {
    position: absolute;
    right: 13px;
    bottom: 13px;
    display: flex;

    .relation {
      position: relative;
      width: 34px;
      height: 34px;
      margin-right: 13px;
      border-radius: 9999px;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;

      &:last-of-type {
        margin-right: 0;
      }

      ion-img {
        max-height: 17px;
        min-height: 17px;
      }

      .tooltip {
        position: absolute;
        display: flex;
        opacity: 0;
        font-size: 14px;
        align-items: center;
        height: 50px;
        padding: 18px 13px;
        box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
        border-radius: 28px 28px 0px 28px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        white-space: nowrap;
        bottom: 29px;
        right: 29px;
        pointer-events: none;
        transition: all 0.15s ease-in-out;

        &:has(.list) {
          flex-direction: column;
          height: auto;
        }

        .list {
          display: flex;
          width: 100%;
          align-items: center;

          margin-bottom: 8px;

          &:last-of-type {
            margin-bottom: 0;
          }

          .avatar {
            min-width: 28px;
            min-height: 28px;
            max-width: 28px;
            max-height: 28px;
            margin-right: 7px;
          }

          span {
            margin-right: 50px;
          }

          .remove {
            margin-left: auto;
          }
        }

        .marker {
          min-width: 19px;
          min-height: 25px;
          margin-right: 6px;
        }

        span {
          font-weight: 700;
          margin-right: 20px;
        }

        .remove {
          min-width: 25px;
          width: 25px;
          height: 25px;
          border-radius: 9999px;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          ion-img {
            min-width: 10.5px;
            min-height: 11.67px;
            border-radius: 0;
          }
        }
      }

      &:hover .tooltip {
        opacity: 1;
        pointer-events: all;
        transition: all 0.15s ease-in-out;
      }
    }
  }
}

.lottie-animation {
  width: 68px;
  height: 58px;
}

ion-avatar {
  width: 90px;
  height: 90px;
  margin: 40px auto 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -5px 12px 19px 1px rgba(0, 0, 0, 0.13);
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    border-radius: 50%;
    border: 1px dashed #cccccc;
  }

  &.is-invalid {
    &:before {
      border-width: 2px;
      border-color: #e45043;
    }
  }
}

.post-selectors {
  display: flex;
  justify-content: end;
  margin-bottom: 11px;

  .selector-box {
    position: relative;

    .tooltip {
      position: absolute;
      display: flex;
      opacity: 0;
      font-size: 14px;
      align-items: center;
      height: 50px;
      padding-left: 18px;
      padding-right: 25px;
      box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
      border-radius: 28px;
      background-color: white;
      white-space: nowrap;
      bottom: 53px;
      right: -10px;
      transition: all 0.15s ease-in-out;
      pointer-events: none;

      ion-img {
        width: 13px;
        height: 13px;
        min-width: 13px;
        min-height: 13px;
        margin-right: 10px;
      }

      span.danger {
        color: #e6224a;
        font-weight: bold;
        letter-spacing: -0.3px;
        margin-right: 2px;
      }
    }

    &:hover .tooltip {
      opacity: 1;
      transition: all 0.15s ease-in-out;
    }

    &:first-of-type {
      margin-right: 5px;
    }
  }

  .main-selector {
    width: 42px;
    height: 42px;
    min-width: 42px;
    min-height: 42px;
    border: 4px solid white;
    border-radius: 99999px;
    background: #efefef;

    &.selected::part(image) {
      max-height: 24px;
      margin-top: 5px;
    }
  }
}

.message-box {
  display: flex;

  ion-img {
    width: 46px;
    height: 46px;
    min-width: 46px;
    min-height: 46px;
    border: 4px solid white;
    margin-right: 10px;
    border-radius: 99999px;
    filter: drop-shadow(-3px 5px 11px rgba(0, 0, 0, 0.15));

    &::part(image) {
      border-radius: 99999px;
    }
  }

  .form-group {
    width: 100%;
  }
}

ion-textarea {
  border: none;
  box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  font-weight: 700;
  --padding-start: 15px;
  --padding-end: 15px;
  --padding-top: 8px;
  --padding-bottom: 8px;
  font-size: 14px;
}

.submit {
  --background: #e6224a;
  --box-shadow: none;
  max-width: 242px;
  margin: 0 auto;
  font-size: 16px;
  letter-spacing: -0.64px;
  margin-top: 90px;
}
</style>
